
import {computed, defineComponent, onMounted, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import Tagify from '@yaireo/tagify';
import {Actions} from "@/store/enums/StoreEnums";
import roomPriceType from "@/core/data/roompricetype";
import roomPricePlatform from "@/core/data/roompriceplatform";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import weekDaysList from "@/core/data/weekdays";
import roomMinAccType from "@/core/data/roomminacctype";
import roomProfitType from "@/core/data/roomprofittype";
import curList from "@/core/data/currency";
import flightType from "@/core/data/flighttypes";
import ticketTypes from "@/core/data/tickettypes";
import genderTypes from "@/core/data/gender";
import paymentMethods from "@/core/data/paymentMethods";
import airlineClassesTypes from "@/core/data/airlineclassestype";

import MasterConfig from "@/core/config/MasterConfig";
import curExchangeType from "@/core/data/curexchangetype";
import {commonf} from "@/core/filters/common";
import {dateConvert} from "@/core/filters/datatime";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";


interface updReservations {
  resID: string;
  resDepartment: string;
  resAccount: string;
  resMarket: string;
  resADTCount: number,
  resCHDCount: number,
  resINFCount: number,
  resSaleCur: string,

  resCurRateStatus: string;
  resCurRateID: string;
  resCurRate: Array<any>;

  resContactName: string;
  resContactEmail: string;
  resContactPhone: string;

  itemProduct: Array<any>;
  itemProcess: Array<any>;
  itemProcessDate: Array<any>;
  itemTrip: string;
  itemFlightCount: number;

  itemStartDate: Array<any>;
  itemFinishDate: Array<any>;
  itemSupplier: Array<any>;
  itemFrom: Array<any>;
  itemTo: Array<any>;
  itemProductCount: Array<any>;
  itemADTCount: number;
  itemCHDCount: number;
  itemINFCount: number;
  itemCur: Array<any>;
  itemCostCur: Array<any>;
  itemTotalCost: Array<any>;
  itemTotalCurCost: Array<any>;
  itemTotalAccountPrice: Array<any>;
  itemTotalPrice: Array<any>;

  itemRemarksVC1: Array<any>;
  itemRemarksVC2: Array<any>;
  itemRemarksVC3: Array<any>;
  itemRemarksVC4: Array<any>;
  itemRemarksVC5: Array<any>;
  itemRemarksVC6: Array<any>;
  itemRemarksDC1: Array<any>;
  itemRemarksJSON1: Array<any>;
  itemRemarksJSON2: Array<any>;
  itemRemarksJSON3: Array<any>;

  itemStartTime: Array<any>;
  itemFinishTime: Array<any>;

  paxADTGender: Array<any>;
  paxADTName: Array<any>;
  paxADTSurname: Array<any>;
  paxADTBDate: Array<any>;
  paxADTID: Array<any>;
  paxADTTicketNo: Array<any>;
  paxADTReturnTicketNo: Array<any>;

  paxCHDGender: Array<any>;
  paxCHDName: Array<any>;
  paxCHDSurname: Array<any>;
  paxCHDBDate: Array<any>;
  paxCHDID: Array<any>;
  paxCHDTicketNo: Array<any>;
  paxCHDReturnTicketNo: Array<any>;

  paxINFGender: Array<any>;
  paxINFName: Array<any>;
  paxINFSurname: Array<any>;
  paxINFBDate: Array<any>;
  paxINFID: Array<any>;
  paxINFTicketNo: Array<any>;
  paxINFReturnTicketNo: Array<any>;

  paxExcelStatus: boolean;
  paxExcelSelected: string;

  paxPriceADT: Array<any>;
  paxPriceCHD: Array<any>;
  paxPriceINF: Array<any>;

  paxAccountPriceADT: Array<any>;
  paxAccountPriceCHD: Array<any>;
  paxAccountPriceINF: Array<any>;

  paxCostADT: Array<any>;
  paxCostCHD: Array<any>;
  paxCostINF: Array<any>;

  paxTaxADT: Array<any>;
  paxTaxCHD: Array<any>;
  paxTaxINF: Array<any>;

  itemSupplierPaymentType: string;
  itemSupplierPaymentMethod: string;
  itemSupplierPaymentAmount: string;
  itemSupplierPaymentCur: string;


  itemPaymentStatus: boolean;
  itemPaymentType: string;
  itemPaymentMethod: string;
  itemPaymentAmount: string;
  itemPaymentCur: string;

  resCurTRY: string;
  resCurUSD: string;
  resCurEUR: string;
  resCurSTG: string;
}

export default defineComponent({
  name: "new-room-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
    }
  },
  props: {
    itemID: String,
    resID: String,
    prcKey: String,
  },
  watch: {
    itemID() {
      if(this.itemID>0){
        this.myCont =false;
        const payloadit = {
          "ID": this.itemID
        };
        store.dispatch(Actions.RESERVATION_PRODUCTS_DETAILS, payloadit);
      }
    },
  },

  setup(props) {

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);


    const myCont = ref(false);


    function setDatePicker(sDate) {
      let myConfigS = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: sDate,
      };


      let tmpFinishDate = flatpickr("#kt_datepicker_1", myConfigS);

    }

    const myDetailsRes = computed(() => {
      let tmpArry = store.getters.reservationsDetails;
      if (tmpArry.ID !== undefined) {
        console.log(tmpArry)
        setResData(tmpArry);
      }
      return tmpArry;
    });

    function setResData(tmpArry) {
      updReservations.value.resMarket = tmpArry.Market;
      updReservations.value.resDepartment = tmpArry.Department;
      updReservations.value.resAccount = tmpArry.AccountID;
    }

    const myDetailsProduct = computed(() => {
      let tmpArry = store.getters.reservationProductsDetails;
      if (tmpArry.ID !== undefined) {
        setItemValue(tmpArry);
      }
      return tmpArry;

    });

    function setItemValue(tmpArry) {

      console.log(tmpArry);
      updReservations.value.itemProcessDate[0] = dateConvert.method.dateToflatpicker(tmpArry.ProcessDate);
      updReservations.value.itemSupplier[0] = tmpArry.SupplierID;
      updReservations.value.itemFrom[0] = "(" + tmpArry.From + ")";
      updReservations.value.itemTo[0] = "(" + tmpArry.To + ")";

      updReservations.value.itemRemarksVC1[0] = tmpArry.RemarksVC1;
      updReservations.value.itemRemarksVC2[0] = tmpArry.RemarksVC2;
      updReservations.value.itemRemarksVC3[0] = tmpArry.RemarksVC3;
      updReservations.value.itemRemarksVC4[0] = tmpArry.RemarksVC4;
      updReservations.value.itemRemarksVC5[0] = tmpArry.RemarksVC5;
      updReservations.value.itemRemarksVC6[0] = tmpArry.RemarksVC6;

      updReservations.value.itemStartDate[0] = dateConvert.method.convertToDate(tmpArry.StartDate);
      updReservations.value.itemFinishDate[0] = dateConvert.method.convertToDate(tmpArry.FinishDate);

      updReservations.value.itemStartTime[0] = (JSON.parse(tmpArry.RemarksJSON3))['FlightDepTime'];
      updReservations.value.itemFinishTime[0] = (JSON.parse(tmpArry.RemarksJSON3))['FlightArrTime'];


      updReservations.value.paxPriceADT[0] = (JSON.parse(tmpArry.RemarksJSON2))['Price']['ADT'];
      updReservations.value.paxPriceCHD[0] = (JSON.parse(tmpArry.RemarksJSON2))['Price']['CHD'];
      updReservations.value.paxPriceINF[0] = (JSON.parse(tmpArry.RemarksJSON2))['Price']['INF'];
      updReservations.value.resSaleCur = (JSON.parse(tmpArry.RemarksJSON2))['Price']['CUR'];

      updReservations.value.paxAccountPriceADT[0] = (JSON.parse(tmpArry.RemarksJSON2))['AccountPrice']['ADT'];
      updReservations.value.paxAccountPriceCHD[0] = (JSON.parse(tmpArry.RemarksJSON2))['AccountPrice']['CHD'];
      updReservations.value.paxAccountPriceINF[0] = (JSON.parse(tmpArry.RemarksJSON2))['AccountPrice']['INF'];

      updReservations.value.paxCostADT[0] = (JSON.parse(tmpArry.RemarksJSON2))['Cost']['ADT'];
      updReservations.value.paxCostCHD[0] = (JSON.parse(tmpArry.RemarksJSON2))['Cost']['CHD'];
      updReservations.value.paxCostINF[0] = (JSON.parse(tmpArry.RemarksJSON2))['Cost']['INF'];
      updReservations.value.itemCostCur[0] = (JSON.parse(tmpArry.RemarksJSON2))['Cost']['CUR'];

      updReservations.value.paxTaxADT[0] = (JSON.parse(tmpArry.RemarksJSON2))['Tax']['ADT'];
      updReservations.value.paxTaxCHD[0] = (JSON.parse(tmpArry.RemarksJSON2))['Tax']['CHD'];
      updReservations.value.paxTaxINF[0] = (JSON.parse(tmpArry.RemarksJSON2))['Tax']['INF'];

      updReservations.value.itemADTCount = tmpArry.ADTCount;
      updReservations.value.itemCHDCount = tmpArry.CHDCount;
      updReservations.value.itemINFCount = tmpArry.INFCount;

      let tmpPax = JSON.parse(tmpArry.RemarksJSON1);
      let myia = 0;

      if (tmpArry.ADTCount>0) {
        updReservations.value.paxADTGender[myia] = tmpPax['PaxGender'];
        updReservations.value.paxADTName[myia] = tmpPax['PaxName'];
        updReservations.value.paxADTSurname[myia] = tmpPax['PaxSurname'];
        updReservations.value.paxADTBDate[myia] = tmpPax['PaxBirthdate'];
        updReservations.value.paxADTID[myia] = tmpPax['PaxID'];
        updReservations.value.paxADTTicketNo[myia] = tmpPax['PaxTicketNo'];
      }

      if (tmpArry.CHDCount>0) {
        updReservations.value.paxCHDGender[myia] = tmpPax['PaxGender'];
        updReservations.value.paxCHDName[myia] = tmpPax['PaxName'];
        updReservations.value.paxCHDSurname[myia] = tmpPax['PaxSurname'];
        updReservations.value.paxCHDBDate[myia] = tmpPax['PaxBirthdate'];
        updReservations.value.paxCHDID[myia] = tmpPax['PaxID'];
        updReservations.value.paxCHDTicketNo[myia] = tmpPax['PaxTicketNo'];
      }

      if (tmpArry.INFCount>0) {
        updReservations.value.paxINFGender[myia] = tmpPax['PaxGender'];
        updReservations.value.paxINFName[myia] = tmpPax['PaxName'];
        updReservations.value.paxINFSurname[myia] = tmpPax['PaxSurname'];
        updReservations.value.paxINFBDate[myia] = tmpPax['PaxBirthdate'];
        updReservations.value.paxINFID[myia] = tmpPax['PaxID'];
        updReservations.value.paxINFTicketNo[myia] = tmpPax['PaxTicketNo'];
      }
      myCont.value=true;
      if (tmpArry.ProcessDate) setDatePicker(dateConvert.method.convertToDate(tmpArry.ProcessDate))
    }

    const updReservations = ref<updReservations>({
      resID: "",
      resDepartment: "",
      resAccount: "",
      resMarket: "",
      resADTCount: 1,
      resCHDCount: 0,
      resINFCount: 0,
      resSaleCur: "",

      resCurRateStatus: "AUTO",
      resCurRateID: "",
      resCurRate: [],

      resContactName: "",
      resContactEmail: "",
      resContactPhone: "",

      itemProduct: [],
      itemProcess: [],
      itemTrip: "ONAWAY",
      itemProcessDate: [],
      itemFlightCount: 1,

      itemStartDate: [],
      itemFinishDate: [],
      itemSupplier: [],
      itemFrom: ['',''],
      itemTo: ['',''],
      itemProductCount: [],
      itemADTCount: 1,
      itemCHDCount: 0,
      itemINFCount: 0,
      itemCur: [],
      itemCostCur: [],
      itemTotalCost: [],
      itemTotalCurCost: [],
      itemTotalAccountPrice: [],
      itemTotalPrice: [],

      itemRemarksVC1: [],
      itemRemarksVC2: [],
      itemRemarksVC3: [],
      itemRemarksVC4: [],
      itemRemarksVC5: [],
      itemRemarksVC6: [],
      itemRemarksDC1: [],
      itemRemarksJSON1: [],
      itemRemarksJSON2: [],
      itemRemarksJSON3: [],

      itemStartTime: [],
      itemFinishTime: [],

      paxADTGender: [],
      paxADTName: [],
      paxADTSurname: [],
      paxADTBDate: [],
      paxADTID: [],
      paxADTTicketNo: [],
      paxADTReturnTicketNo: [],

      paxCHDGender: [],
      paxCHDName: [],
      paxCHDSurname: [],
      paxCHDBDate: [],
      paxCHDID: [],
      paxCHDTicketNo: [],
      paxCHDReturnTicketNo: [],

      paxExcelStatus: false,
      paxExcelSelected: "false",

      paxPriceADT: [],
      paxPriceCHD: [],
      paxPriceINF: [],

      paxAccountPriceADT: [],
      paxAccountPriceCHD: [],
      paxAccountPriceINF: [],

      paxCostADT: [],
      paxCostCHD: [],
      paxCostINF: [],

      paxTaxADT: [],
      paxTaxCHD: [],
      paxTaxINF: [],

      paxINFGender: [],
      paxINFName: [],
      paxINFSurname: [],
      paxINFBDate: [],
      paxINFID: [],
      paxINFTicketNo: [],
      paxINFReturnTicketNo: [],

      itemSupplierPaymentType: "",
      itemSupplierPaymentMethod: "",
      itemSupplierPaymentAmount: "",
      itemSupplierPaymentCur: "",

      itemPaymentStatus: true,
      itemPaymentType: "",
      itemPaymentMethod: "",
      itemPaymentAmount: "",
      itemPaymentCur: "",

      resCurTRY: "",
      resCurUSD: "",
      resCurEUR: "",
      resCurSTG: "",

    });

    const myCur = computed(() => {
      let tmpData = store.getters.curRateList;
      //if (tmpData.value !== undefined) updReservations.value.resCurTRY = "1";
      if (tmpData.data !== undefined) {
        return tmpData.data[0];
      } else {
        return {
          CurTRY: 1,
          CurUSD: 0,
          CurEUR: 0,
          CurSTG: 0,
        }
      }

    });

    const myAirlineList = computed(() => {
      return store.getters.airlineList;
    });
    const myAirlineClassesList = computed(() => {
      return store.getters.airlineClassesList;
    });
    const mySupplierList = computed(() => {
      return store.getters.suppliersList;
    });
    const myAirportList = computed(() => {
      return store.getters.airportsList;
    });
    const myAirportsTitle = computed(() => {
      let tmpArray = [];
      if (myAirportList.value.data !== undefined) {
        for (var i of myAirportList.value.data) {
          tmpArray.push("(" + i.IATACode + ") - " + i.Title + " - " + i.City);
        }
      }
      return tmpArray;
    });
    const getAirports = (ID) => {
      let myFrom = document.getElementById(ID) as HTMLInputElement;
      let myPayload = {};
      if (myFrom.value.length == 3) {
        myPayload = {
          'IATACode': myFrom.value.toUpperCase()
        }
        store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
      } else if (myFrom.value.length == 4) {
        myPayload = {
          'Title': myFrom.value
        }
        store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
      }
    }


    const myTotalFlightPrice = computed(() => {
      let myTCur = updReservations.value.resSaleCur;

      let myCostCur = updReservations.value.itemCostCur[0];
      let myCurRate = 0;
      let myCostCurRate = 0;
      if (myTCur == 'TRY') myCurRate = myCur.value.CurTRY;
      if (myTCur == 'USD') myCurRate = myCur.value.CurUSD;
      if (myTCur == 'EUR') myCurRate = myCur.value.CurEUR;
      if (myTCur == 'STG') myCurRate = myCur.value.CurSTG;

      if (myCostCur == 'TRY') myCostCurRate = myCur.value.CurTRY;
      if (myCostCur == 'USD') myCostCurRate = myCur.value.CurUSD;
      if (myCostCur == 'EUR') myCostCurRate = myCur.value.CurEUR;
      if (myCostCur == 'STG') myCostCurRate = myCur.value.CurSTG;
      let tmpCostResCur = {
        CUR: "",
        TOTALCOST: 0,
        ADT: 0,
        CHD: 0,
        INF: 0,
      };
      if (myTCur == myCostCur) {
        tmpCostResCur = {
          CUR: myTCur,
          TOTALCOST: (updReservations.value.paxCostADT[0] * updReservations.value.itemADTCount) + (updReservations.value.paxCostCHD[0] * updReservations.value.itemCHDCount) + (updReservations.value.paxCostINF[0] * updReservations.value.itemINFCount),
          ADT: Number((updReservations.value.paxCostADT[0] * updReservations.value.itemADTCount)),
          CHD: Number((updReservations.value.paxCostCHD[0] * updReservations.value.itemCHDCount)),
          INF: Number((updReservations.value.paxCostINF[0] * updReservations.value.itemINFCount)),
        }
      } else {
        let tmpADTPrice = ((updReservations.value.paxCostADT[0] * updReservations.value.itemADTCount) * myCostCurRate) / myCurRate;
        let tmpCHDPrice = ((updReservations.value.paxCostCHD[0] * updReservations.value.itemCHDCount) * myCostCurRate) / myCurRate;
        let tmpINFPrice = ((updReservations.value.paxCostINF[0] * updReservations.value.itemINFCount) * myCostCurRate) / myCurRate;

        tmpCostResCur = {
          CUR: myCostCur,
          TOTALCOST: Number((tmpADTPrice + tmpCHDPrice + tmpINFPrice).toFixed(2)),
          ADT: Number((tmpADTPrice).toFixed(2)),
          CHD: Number((tmpCHDPrice).toFixed(2)),
          INF: Number((tmpINFPrice).toFixed(2)),
        }
      }

      /*******RETURN FLIGHT*******/
      let myCostCurReturn = updReservations.value.itemCostCur[1];
      let myCostCurRateReturn = 0;

      if (myCostCurReturn == 'TRY') myCostCurRateReturn = myCur.value.CurTRY;
      if (myCostCurReturn == 'USD') myCostCurRateReturn = myCur.value.CurUSD;
      if (myCostCurReturn == 'EUR') myCostCurRateReturn = myCur.value.CurEUR;
      if (myCostCurReturn == 'STG') myCostCurRateReturn = myCur.value.CurSTG;
      let tmpCostResCurReturn = {
        CUR: "",
        TOTALCOST: 0,
        ADT: 0,
        CHD: 0,
        INF: 0,
      };
      if (myTCur == myCostCurReturn) {
        tmpCostResCurReturn = {
          CUR: myTCur,
          TOTALCOST: (updReservations.value.paxCostADT[1] * updReservations.value.itemADTCount) + (updReservations.value.paxCostCHD[1] * updReservations.value.itemCHDCount) + (updReservations.value.paxCostINF[1] * updReservations.value.itemINFCount),
          ADT: Number((updReservations.value.paxCostADT[1] * updReservations.value.itemADTCount)),
          CHD: Number((updReservations.value.paxCostCHD[1] * updReservations.value.itemCHDCount)),
          INF: Number((updReservations.value.paxCostINF[1] * updReservations.value.itemINFCount)),
        }
      } else {
        let tmpADTPrice = ((updReservations.value.paxCostADT[1] * updReservations.value.itemADTCount) * myCostCurRateReturn) / myCurRate;
        let tmpCHDPrice = ((updReservations.value.paxCostCHD[1] * updReservations.value.itemCHDCount) * myCostCurRateReturn) / myCurRate;
        let tmpINFPrice = ((updReservations.value.paxCostINF[1] * updReservations.value.itemINFCount) * myCostCurRateReturn) / myCurRate;

        tmpCostResCurReturn = {
          CUR: myCostCur,
          TOTALCOST: Number((tmpADTPrice + tmpCHDPrice + tmpINFPrice).toFixed(2)),
          ADT: Number((tmpADTPrice).toFixed(2)),
          CHD: Number((tmpCHDPrice).toFixed(2)),
          INF: Number((tmpINFPrice).toFixed(2)),
        }
      }


      let myTempPrices = {
        CUR: updReservations.value.resSaleCur,
        COSTCUR: updReservations.value.itemCostCur,
        ITEMS: [
          {
            /*******DEP FLIGHT*********/
            PRICE: {
              ADT: (updReservations.value.paxPriceADT[0] * updReservations.value.itemADTCount).toFixed(2),
              CHD: (updReservations.value.paxPriceCHD[0] * updReservations.value.itemCHDCount).toFixed(2),
              INF: (updReservations.value.paxPriceINF[0] * updReservations.value.itemINFCount).toFixed(2),
            },
            COST: {
              ADT: (updReservations.value.paxCostADT[0] * updReservations.value.itemADTCount).toFixed(2),
              CHD: (updReservations.value.paxCostCHD[0] * updReservations.value.itemCHDCount).toFixed(2),
              INF: (updReservations.value.paxCostINF[0] * updReservations.value.itemINFCount).toFixed(2),
            },
            TAX: {
              ADT: (updReservations.value.paxTaxADT[0] * updReservations.value.itemADTCount).toFixed(2),
              CHD: (updReservations.value.paxTaxCHD[0] * updReservations.value.itemCHDCount).toFixed(2),
              INF: (updReservations.value.paxTaxINF[0] * updReservations.value.itemINFCount).toFixed(2),
            },
            TOTAL: {
              PRICE: ((updReservations.value.paxPriceADT[0] * updReservations.value.itemADTCount) + ((updReservations.value.paxPriceCHD[0] || 0) * updReservations.value.itemCHDCount) + ((updReservations.value.paxPriceINF[0] || 0) * updReservations.value.itemINFCount)).toFixed(2),
              COST: ((updReservations.value.paxCostADT[0] * updReservations.value.itemADTCount) + ((updReservations.value.paxCostCHD[0] || 0) * updReservations.value.itemCHDCount) + ((updReservations.value.paxCostINF[0] || 0) * updReservations.value.itemINFCount)).toFixed(2),
              TAX: ((updReservations.value.paxTaxADT[0] * updReservations.value.itemADTCount) + ((updReservations.value.paxTaxCHD[0] || 0) * updReservations.value.itemCHDCount) + ((updReservations.value.paxTaxINF[0] || 0) * updReservations.value.itemINFCount)).toFixed(2),
              PROFIT: Number((updReservations.value.paxPriceADT[0] * updReservations.value.itemADTCount) + ((updReservations.value.paxPriceCHD[0] || 0) * updReservations.value.itemCHDCount) + ((updReservations.value.paxPriceINF[0] || 0) * updReservations.value.itemINFCount)) - Number(tmpCostResCur.TOTALCOST.toFixed(2)),
            },
          },
          {
            /*******ARR FLIGHT*********/
            PRICE: {
              ADT: (updReservations.value.paxPriceADT[1] * updReservations.value.itemADTCount).toFixed(2),
              CHD: (updReservations.value.paxPriceCHD[1] * updReservations.value.itemCHDCount).toFixed(2),
              INF: (updReservations.value.paxPriceINF[1] * updReservations.value.itemINFCount).toFixed(2),
            },
            COST: {
              ADT: (updReservations.value.paxCostADT[1] * updReservations.value.itemADTCount).toFixed(2),
              CHD: (updReservations.value.paxCostCHD[1] * updReservations.value.itemCHDCount).toFixed(2),
              INF: (updReservations.value.paxCostINF[1] * updReservations.value.itemINFCount).toFixed(2),
            },
            TAX: {
              ADT: (updReservations.value.paxTaxADT[1] * updReservations.value.itemADTCount).toFixed(2),
              CHD: (updReservations.value.paxTaxCHD[1] * updReservations.value.itemCHDCount).toFixed(2),
              INF: (updReservations.value.paxTaxINF[1] * updReservations.value.itemINFCount).toFixed(2),
            },
            TOTAL: {
              PRICE: ((updReservations.value.paxPriceADT[1] * updReservations.value.itemADTCount) + ((updReservations.value.paxPriceCHD[1] || 0) * updReservations.value.itemCHDCount) + ((updReservations.value.paxPriceINF[1] || 0) * updReservations.value.itemINFCount)).toFixed(2),
              COST: ((updReservations.value.paxCostADT[1] * updReservations.value.itemADTCount) + ((updReservations.value.paxCostCHD[1] || 0) * updReservations.value.itemCHDCount) + ((updReservations.value.paxCostINF[1] || 0) * updReservations.value.itemINFCount)).toFixed(2),
              TAX: ((updReservations.value.paxTaxADT[1] * updReservations.value.itemADTCount) + ((updReservations.value.paxTaxCHD[1] || 0) * updReservations.value.itemCHDCount) + ((updReservations.value.paxTaxINF[1] || 0) * updReservations.value.itemINFCount)).toFixed(2),
              PROFIT: Number((updReservations.value.paxPriceADT[1] * updReservations.value.itemADTCount) + ((updReservations.value.paxPriceCHD[1] || 0) * updReservations.value.itemCHDCount) + ((updReservations.value.paxPriceINF[1] || 0) * updReservations.value.itemINFCount)) - Number(tmpCostResCurReturn.TOTALCOST.toFixed(2)),
            },
          },
        ],
        TOTALPRICE: {
          PRICE: (((updReservations.value.paxPriceADT[0] + (updReservations.value.paxPriceADT[1] || 0)) * updReservations.value.itemADTCount) +
              (((updReservations.value.paxPriceCHD[0] || 0) + (updReservations.value.paxPriceCHD[1] || 0)) * updReservations.value.itemCHDCount) +
              (((updReservations.value.paxPriceINF[0] || 0) + (updReservations.value.paxPriceINF[1] || 0)) * updReservations.value.itemINFCount)).toFixed(2)
        },
        COST_SALE_CUR: {
          CUR: myCostCur,
          TOTALCOST: (tmpCostResCur.ADT || 0) + (tmpCostResCur.CHD || 0) + (tmpCostResCur.INF || 0) + (tmpCostResCurReturn.ADT || 0) + (tmpCostResCurReturn.CHD || 0) + (tmpCostResCurReturn.INF || 0),
          ADT: Number(tmpCostResCur.ADT).toFixed(2),
          CHD: Number(tmpCostResCur.CHD + tmpCostResCurReturn.CHD).toFixed(2),
          INF: Number(tmpCostResCur.INF + tmpCostResCurReturn.INF).toFixed(2),
        },
        COST_SALE_CUR_DEPERTURE: tmpCostResCur,
        COST_SALE_CUR_ARRIVAL: tmpCostResCurReturn,
      };
      console.log(myTempPrices);
      return myTempPrices;
    });


    const validationSchema = Yup.object().shape({
      roomTitle: Yup.string().required().label("Oda İsmi"),
      roomCategory: Yup.string().required().label("Oda Kategory"),
      roomADT: Yup.string().required().label("Yetişkin Kapasite"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      let payloadFlights = {};
      let payloadPax = {};

      if (updReservations.value.itemADTCount>0) {
        payloadPax= {
          PaxType: 'ADT',
          PaxGender: updReservations.value.paxADTGender[0],
          PaxName: updReservations.value.paxADTName[0],
          PaxSurname: updReservations.value.paxADTSurname[0],
          PaxBirthdate: updReservations.value.paxADTBDate[0],
          PaxID: updReservations.value.paxADTID[0],
          PaxTicketNo: updReservations.value.paxADTTicketNo[0],
          PaxReturnTicketNo: updReservations.value.paxADTReturnTicketNo[0],
        };
      }

      if (updReservations.value.itemCHDCount>0) {
        payloadPax = {
          PaxType: 'CHD',
          PaxGender: updReservations.value.paxCHDGender[0],
          PaxName: updReservations.value.paxCHDName[0],
          PaxSurname: updReservations.value.paxCHDSurname[0],
          PaxBirthdate: updReservations.value.paxCHDBDate[0],
          PaxID: updReservations.value.paxCHDID[0],
          PaxTicketNo: updReservations.value.paxCHDTicketNo[0],
          PaxReturnTicketNo: updReservations.value.paxCHDReturnTicketNo[0],
        };
      }

      if (updReservations.value.itemINFCount>0) {
        payloadPax = {
          PaxType: 'INF',
          PaxGender: updReservations.value.paxINFGender[0],
          PaxName: updReservations.value.paxINFName[0],
          PaxSurname: updReservations.value.paxINFSurname[0],
          PaxBirthdate: updReservations.value.paxINFBDate[0],
          PaxID: updReservations.value.paxINFID[0],
          PaxTicketNo: updReservations.value.paxINFTicketNo[0],
          PaxReturnTicketNo: updReservations.value.paxINFReturnTicketNo[0],
        };
      }

      for (let i = 0; i < updReservations.value.itemFlightCount; i++) {
        let myFlightFrom = document.getElementById("flightFromUp" + i) as HTMLInputElement;
        let myFlightTo = document.getElementById("flightToUp" + i) as HTMLInputElement;
        let matchesFrom = myFlightFrom.value.match(/\(([^)]+)\)/);
        let matchesTo = myFlightTo.value.match(/\(([^)]+)\)/);
        //console.log(matchesTo);
        //console.log(matchesFrom);
        if (matchesFrom) {
          updReservations.value.itemFrom[i] = matchesFrom[1];
        }
        if (matchesTo) {
          updReservations.value.itemTo[i] = matchesTo[1];
        }
        payloadFlights[i] = {
          ID: props.itemID,
          ReservationID: props.resID,
          ProductType: 'FLIGHT',
          ProcessType: 'RESERVATION',
          ProcessDate: dateConvert.method.flatpickerToDateYM(updReservations.value.itemProcessDate[i]),
          StartDate: dateConvert.method.convertToDateYM(updReservations.value.itemStartDate[i]),
          FinishDate: dateConvert.method.convertToDateYM(updReservations.value.itemFinishDate[i]),
          SupplierID: updReservations.value.itemSupplier[i],
          From: updReservations.value.itemFrom[i],
          To: updReservations.value.itemTo[i],
          Campaign: '{}',
          ProductCount: updReservations.value.itemADTCount + updReservations.value.itemCHDCount,
          ADTCount: updReservations.value.itemADTCount,
          CHDCount: updReservations.value.itemCHDCount,
          INFCount: updReservations.value.itemINFCount,
          Cur: updReservations.value.resSaleCur,
          CostCur: updReservations.value.itemCostCur[i],
          TotalCost: myTotalFlightPrice.value.ITEMS[i].TOTAL.COST,
          TotalAccountPrice: myTotalFlightPrice.value.ITEMS[i].TOTAL.PRICE,
          TotalPrice: myTotalFlightPrice.value.ITEMS[i].TOTAL.PRICE,
          TotalCurCost: (i == 0) ? myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.TOTALCOST : myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.TOTALCOST,
          RemarksVC1: updReservations.value.itemRemarksVC1[i],
          RemarksVC2: updReservations.value.itemRemarksVC2[i],
          RemarksVC3: updReservations.value.itemRemarksVC3[i],
          RemarksVC4: updReservations.value.itemRemarksVC4[i],
          RemarksVC5: updReservations.value.itemRemarksVC5[i],
          RemarksVC6: updReservations.value.itemRemarksVC6[i],
          RemarksJSON1: JSON.stringify(payloadPax),
          RemarksJSON2: JSON.stringify({
            Price: {
              ADT: updReservations.value.paxPriceADT[i],
              CHD: (updReservations.value.itemCHDCount >= 1) ? updReservations.value.paxPriceCHD[i] : 0,
              INF: (updReservations.value.itemINFCount >= 1) ? updReservations.value.paxPriceINF[i] : 0,
              CUR: updReservations.value.resSaleCur,
            },
            AccountPrice: {
              ADT: updReservations.value.paxPriceADT[i],
              CHD: (updReservations.value.itemCHDCount >= 1) ? updReservations.value.paxPriceCHD[i] : 0,
              INF: (updReservations.value.itemINFCount >= 1) ? updReservations.value.paxPriceINF[i] : 0,
              CUR: updReservations.value.resSaleCur,
            },
            Cost: {
              ADT: updReservations.value.paxCostADT[i],
              CHD: (updReservations.value.itemCHDCount >= 1) ? updReservations.value.paxCostCHD[i] : 0,
              INF: (updReservations.value.itemINFCount >= 1) ? updReservations.value.paxCostINF[i] : 0,
              CUR: updReservations.value.itemCostCur[i],
              ADTCURCOST: (i == 0) ? (myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.ADT / updReservations.value.itemADTCount) : (myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.ADT / updReservations.value.itemADTCount),
              CHDCURCOST: (i == 0) ? (myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.CHD / updReservations.value.itemCHDCount) : (myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.CHD / updReservations.value.itemCHDCount),
              INFCURCOST: (i == 0) ? (myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.INF / updReservations.value.itemINFCount) : (myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.INF / updReservations.value.itemINFCount),
            },
            Tax: {
              ADT: updReservations.value.paxTaxADT[i],
              CHD: (updReservations.value.itemCHDCount >= 1) ? updReservations.value.paxTaxCHD[i] : 0,
              INF: (updReservations.value.itemINFCount >= 1) ? updReservations.value.paxTaxINF[i] : 0,
              CUR: updReservations.value.itemCostCur[i],
            },
          }),
          RemarksJSON3: JSON.stringify({
            FlightDepTime: updReservations.value.itemStartTime[i],
            FlightArrTime: updReservations.value.itemFinishTime[i],
          }),
        };
      }

      let payloadPayment = {
        Method: updReservations.value.itemPaymentType,
        MethodID: updReservations.value.itemPaymentMethod,
        Amount: updReservations.value.itemPaymentAmount,
        Cur: updReservations.value.itemPaymentCur,
      };
      let payloadSupplierPayment = {
        Method: updReservations.value.itemSupplierPaymentType,
        MethodID: updReservations.value.itemSupplierPaymentMethod,
        Amount: updReservations.value.itemSupplierPaymentAmount,
        Cur: updReservations.value.itemSupplierPaymentCur,
      };
      let payloadCur = {
        TRY: myCur.value.CurTRY,
        USD: myCur.value.CurUSD,
        EUR: myCur.value.CurEUR,
        STG: myCur.value.CurSTG,
      };

      const payload1 = {
        ID: props.itemID,
        ReservationID: props.resID,
        Department: updReservations.value.resDepartment,
        Market: updReservations.value.resMarket,
        AccountID: updReservations.value.resAccount,
        //productFlightCount: (returnFlightStatus) ? 1 : 2,
        productData: JSON.stringify(payloadFlights),
        // SaleCur: updReservations.value.resSaleCur,
        //Contact: JSON.stringify(payloadContact),
        //Payment: JSON.stringify(payloadPayment),
        //SupplierPayment: JSON.stringify(payloadSupplierPayment),
        //CurRate: JSON.stringify(payloadCur),
      }

      console.log(payloadFlights[0])

      store.dispatch(Actions.RESERVATIONS_PRODUCT_UPDATE, payloadFlights[0])
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // submitButtonRef.value.disabled = false;
              //submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              //hideModal(newRoomModalRef.value);
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getReservationsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        //submitButtonRef.value.disabled = false;
        //submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };

    const filtClassesDepp = computed(() => {
      if (myAirlineClassesList.value.data != null) {
        return (myAirlineClassesList.value.data).filter(classItems =>
            (classItems.Airline == updReservations.value.itemRemarksVC2[0]) &&
            (classItems.Type == updReservations.value.itemRemarksVC5[0])
        );
      } else {
        return true;
      }
    });


    let returnFlightStatus = ref(false)
    const setTrip = () => {
      if (updReservations.value.itemTrip == 'ONEWAY') {
        returnFlightStatus.value = false;
        updReservations.value.itemFlightCount = 1;
      } else {
        returnFlightStatus.value = true;
        updReservations.value.itemFlightCount = 2;
      }
    }

    const addEventToNumber = () => {
      var priceInput = document.querySelectorAll(".priceInput");
      priceInput.forEach(function (element) {
        console.log(element);
        element.addEventListener("keyup", commonf.inputDecimal, false);
      });
      return true;
    }

    const testFunc = () => {
      let myFlightTo = document.getElementById("flightToUp0") as HTMLInputElement;
      console.log(myFlightTo.value.length);
      return true;
    };


    return {
      myDetailsRes,
      myDetailsProduct,
      testFunc,
      addEventToNumber,
      commonf,
      mySupplierList,
      myAirlineList,
      myAirlineClassesList,
      myAirportsTitle,
      getAirports,
      myCur,
      myTotalFlightPrice,
      filtClassesDepp,
      roomPriceType,
      roomPricePlatform,
      weekDaysList,
      roomMinAccType,
      roomProfitType,
      curList,
      flightType,
      ticketTypes,
      genderTypes,
      paymentMethods,
      airlineClassesTypes,
      curExchangeType,
      returnFlightStatus,
      validationSchema,
      submit,
      submitButtonRef,
      newRoomModalRef,
      updReservations,
      setTrip,
      MasterConfig,
      myCont,
    };
  },
});
