const flightType = {
  "TYPES" : [
    {
      Value : 'ONEWAY',
      Title : 'Tek Yön',
      Shorthand : 'OW',
    },
    {
      Value : 'ROUNDTRIP',
      Title : 'Gidiş/Dönüş',
      Shorthand: 'RT',
    },],
};
export default flightType;
