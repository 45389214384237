const genderTypes = {
  "TYPES" : [
    {
      Value : 'F',
      Title : 'KADIN',
      Shorthand : 'FM',
    },
    {
      Value : 'M',
      Title : 'ERKEK',
      Shorthand: 'ML',
    },],
};
export default genderTypes;
