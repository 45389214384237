
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import Tagify from '@yaireo/tagify';
import {Actions} from "@/store/enums/StoreEnums";
import roomPriceType from "@/core/data/roompricetype";
import roomPricePlatform from "@/core/data/roompriceplatform";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import weekDaysList from "@/core/data/weekdays";
import roomMinAccType from "@/core/data/roomminacctype";
import roomProfitType from "@/core/data/roomprofittype";
import curList from "@/core/data/currency";
import flightType from "@/core/data/flighttypes";
import ticketTypes from "@/core/data/tickettypes";
import genderTypes from "@/core/data/gender";
import paymentMethods from "@/core/data/paymentMethods";
import airlineClassesTypes from "@/core/data/airlineclassestype";

import MasterConfig from "@/core/config/MasterConfig";
import curExchangeType from "@/core/data/curexchangetype";
import {commonf} from "@/core/filters/common";
import {dateConvert} from "@/core/filters/datatime";


interface newReservations {
  resID: string;
  resDepartment: string;
  resAccount: string;
  resMarket: string;
  resADTCount: number,
  resCHDCount: number,
  resINFCount: number,
  resSaleCur: string,

  resCurRateStatus: string;
  resCurRateID: string;
  resCurRate: Array<any>;

  resContactName: string;
  resContactEmail: string;
  resContactPhone: string;

  itemProduct: Array<any>;
  itemProcess: Array<any>;
  itemProcessDate: Array<any>;
  itemTrip: string;
  itemFlightCount: number;

  itemStartDate: Array<any>;
  itemFinishDate: Array<any>;
  itemSupplier: Array<any>;
  itemFrom: Array<any>;
  itemTo: Array<any>;
  itemProductCount: Array<any>;
  itemADTCount: number;
  itemCHDCount: number;
  itemINFCount: number;
  itemCur: Array<any>;
  itemCostCur: Array<any>;
  itemTotalCost: Array<any>;
  itemTotalCurCost: Array<any>;
  itemTotalAccountPrice: Array<any>;
  itemTotalPrice: Array<any>;

  itemRemarksVC1: Array<any>;
  itemRemarksVC2: Array<any>;
  itemRemarksVC3: Array<any>;
  itemRemarksVC4: Array<any>;
  itemRemarksVC5: Array<any>;
  itemRemarksVC6: Array<any>;
  itemRemarksDC1: Array<any>;
  itemRemarksINT2: Array<any>;
  itemRemarksJSON1: Array<any>;
  itemRemarksJSON2: Array<any>;
  itemRemarksJSON3: Array<any>;

  itemStartTime: Array<any>;
  itemFinishTime: Array<any>;

  paxADTGender: Array<any>;
  paxADTName: Array<any>;
  paxADTSurname: Array<any>;
  paxADTBDate: Array<any>;
  paxADTID: Array<any>;
  paxADTTicketNo: Array<any>;
  paxADTReturnTicketNo: Array<any>;

  paxCHDGender: Array<any>;
  paxCHDName: Array<any>;
  paxCHDSurname: Array<any>;
  paxCHDBDate: Array<any>;
  paxCHDID: Array<any>;
  paxCHDTicketNo: Array<any>;
  paxCHDReturnTicketNo: Array<any>;

  paxINFGender: Array<any>;
  paxINFName: Array<any>;
  paxINFSurname: Array<any>;
  paxINFBDate: Array<any>;
  paxINFID: Array<any>;
  paxINFTicketNo: Array<any>;
  paxINFReturnTicketNo: Array<any>;

  paxExcelStatus: boolean;
  paxExcelSelected: string;

  paxPriceADT: Array<any>;
  paxPriceCHD: Array<any>;
  paxPriceINF: Array<any>;

  paxAccountPriceADT: Array<any>;
  paxAccountPriceCHD: Array<any>;
  paxAccountPriceINF: Array<any>;

  paxCostADT: Array<any>;
  paxCostCHD: Array<any>;
  paxCostINF: Array<any>;

  paxTaxADT: Array<any>;
  paxTaxCHD: Array<any>;
  paxTaxINF: Array<any>;

  itemNote: Array<any>;

  itemSupplierPaymentType: string;
  itemSupplierPaymentMethod: string;
  itemSupplierPaymentAmount: string;
  itemSupplierPaymentCur: string;


  itemPaymentStatus: boolean;
  itemPaymentType: string;
  itemPaymentMethod: string;
  itemPaymentAmount: string;
  itemPaymentCur: string;

  resCurTRY: string;
  resCurUSD: string;
  resCurEUR: string;
  resCurSTG: string;
}

export default defineComponent({
  name: "new-room-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {}
  },
  props: {
    itemID: String,
    resID: String,
    prcKey: String,
  },
  watch: {
    itemID() {
      const payloadit = {
        "ID": this.itemID
      };
      store.dispatch(Actions.RESERVATION_PRODUCTS_DETAILS, payloadit);
    },
  },

  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);

    const roomSale = ref(null)
    const roomActive = ref(null)

    function setDatePicker(sDate) {
      let myConfigS = {
        "locale": Turkish,
        dateFormat: "d-m-Y (D)",
        defaultDate: sDate,
      };
      let tmpFinishDate = flatpickr("#kt_datepicker_1", myConfigS);
    }

    const myDetailsRes = computed(() => {
      let tmpArry = store.getters.reservationsDetails;
      if (tmpArry.ID !== undefined) {
        console.log(tmpArry)
        setResData(tmpArry)
      }
      return tmpArry;
    });

    function setResData(tmpArry) {
      newReservations.value.resMarket = tmpArry.Market;
      newReservations.value.resDepartment = tmpArry.Department;
      newReservations.value.resAccount = tmpArry.AccountID;
    }

    const myDetailsProduct = computed(() => {
      let tmpArry = store.getters.reservationProductsDetails;
      if (tmpArry.ID !== undefined) {
        console.log(tmpArry)
        setResProductData(tmpArry)
      }
      return tmpArry;
    });

    function setResProductData(tmpArry) {

      newReservations.value.itemProcessDate[0] = dateConvert.method.dateToflatpicker(tmpArry.ProcessDate);
      newReservations.value.itemSupplier[0] = tmpArry.SupplierID;
      newReservations.value.itemFrom[0] = "(" + tmpArry.From + ")";
      newReservations.value.itemTo[0] = "(" + tmpArry.To + ")";

      newReservations.value.itemRemarksVC1[0] = tmpArry.RemarksVC1;
      newReservations.value.itemRemarksVC2[0] = tmpArry.RemarksVC2;
      newReservations.value.itemRemarksVC3[0] = tmpArry.RemarksVC3;
      newReservations.value.itemRemarksVC4[0] = tmpArry.RemarksVC4;
      newReservations.value.itemRemarksVC5[0] = tmpArry.RemarksVC5;
      newReservations.value.itemRemarksVC6[0] = tmpArry.RemarksVC6;

      newReservations.value.itemRemarksINT2[0] = tmpArry.RemarksINT2;

      newReservations.value.itemStartDate[0] = dateConvert.method.convertToDate(tmpArry.StartDate);
      newReservations.value.itemFinishDate[0] = dateConvert.method.convertToDate(tmpArry.FinishDate);

      newReservations.value.itemStartTime[0] = (JSON.parse(tmpArry.RemarksJSON3))['FlightDepTime'];
      newReservations.value.itemFinishTime[0] = (JSON.parse(tmpArry.RemarksJSON3))['FlightArrTime'];

      newReservations.value.paxPriceADT[0] = (JSON.parse(tmpArry.RemarksJSON2))['Price']['ADT'];
      newReservations.value.paxPriceCHD[0] = (JSON.parse(tmpArry.RemarksJSON2))['Price']['CHD'];
      newReservations.value.paxPriceINF[0] = (JSON.parse(tmpArry.RemarksJSON2))['Price']['INF'];
      newReservations.value.resSaleCur = (JSON.parse(tmpArry.RemarksJSON2))['Price']['CUR'];

      newReservations.value.paxAccountPriceADT[0] = (JSON.parse(tmpArry.RemarksJSON2))['AccountPrice']['ADT'];
      newReservations.value.paxAccountPriceCHD[0] = (JSON.parse(tmpArry.RemarksJSON2))['AccountPrice']['CHD'];
      newReservations.value.paxAccountPriceINF[0] = (JSON.parse(tmpArry.RemarksJSON2))['AccountPrice']['INF'];

      newReservations.value.paxCostADT[0] = (JSON.parse(tmpArry.RemarksJSON2))['Cost']['ADT'];
      newReservations.value.paxCostCHD[0] = (JSON.parse(tmpArry.RemarksJSON2))['Cost']['CHD'];
      newReservations.value.paxCostINF[0] = (JSON.parse(tmpArry.RemarksJSON2))['Cost']['INF'];
      newReservations.value.itemCostCur[0] = (JSON.parse(tmpArry.RemarksJSON2))['Cost']['CUR'];

      newReservations.value.paxTaxADT[0] = (JSON.parse(tmpArry.RemarksJSON2))['Tax']['ADT'];
      newReservations.value.paxTaxCHD[0] = (JSON.parse(tmpArry.RemarksJSON2))['Tax']['CHD'];
      newReservations.value.paxTaxINF[0] = (JSON.parse(tmpArry.RemarksJSON2))['Tax']['INF'];

      newReservations.value.itemNote[0] = tmpArry.Note;


      newReservations.value.itemADTCount = tmpArry.ADTCount;
      newReservations.value.itemCHDCount = tmpArry.CHDCount;
      newReservations.value.itemINFCount = tmpArry.INFCount;

      let tmpPax = JSON.parse(tmpArry.RemarksJSON1);
      let myia = 0;

      if (tmpArry.ADTCount > 0) {
        newReservations.value.paxADTGender[myia] = tmpPax['PaxGender'];
        newReservations.value.paxADTName[myia] = tmpPax['PaxName'];
        newReservations.value.paxADTSurname[myia] = tmpPax['PaxSurname'];
        newReservations.value.paxADTBDate[myia] = tmpPax['PaxBirthdate'];
        newReservations.value.paxADTID[myia] = tmpPax['PaxID'];
        newReservations.value.paxADTTicketNo[myia] = tmpPax['PaxTicketNo'];
      }

      if (tmpArry.CHDCount > 0) {
        newReservations.value.paxCHDGender[myia] = tmpPax['PaxGender'];
        newReservations.value.paxCHDName[myia] = tmpPax['PaxName'];
        newReservations.value.paxCHDSurname[myia] = tmpPax['PaxSurname'];
        newReservations.value.paxCHDBDate[myia] = tmpPax['PaxBirthdate'];
        newReservations.value.paxCHDID[myia] = tmpPax['PaxID'];
        newReservations.value.paxCHDTicketNo[myia] = tmpPax['PaxTicketNo'];
      }

      if (tmpArry.INFCount > 0) {
        newReservations.value.paxINFGender[myia] = tmpPax['PaxGender'];
        newReservations.value.paxINFName[myia] = tmpPax['PaxName'];
        newReservations.value.paxINFSurname[myia] = tmpPax['PaxSurname'];
        newReservations.value.paxINFBDate[myia] = tmpPax['PaxBirthdate'];
        newReservations.value.paxINFID[myia] = tmpPax['PaxID'];
        newReservations.value.paxINFTicketNo[myia] = tmpPax['PaxTicketNo'];
      }


      if (tmpArry.ProcessDate) setDatePicker(dateConvert.method.convertToDate(tmpArry.ProcessDate))
    }

    const newReservations = ref<newReservations>({
      resID: "",
      resDepartment: "",
      resAccount: "",
      resMarket: "",
      resADTCount: 1,
      resCHDCount: 0,
      resINFCount: 0,
      resSaleCur: "",

      resCurRateStatus: "AUTO",
      resCurRateID: "",
      resCurRate: [],

      resContactName: "",
      resContactEmail: "",
      resContactPhone: "",

      itemProduct: [],
      itemProcess: [],
      itemTrip: "ONAWAY",
      itemProcessDate: [],
      itemFlightCount: 1,

      itemStartDate: [],
      itemFinishDate: [],
      itemSupplier: [],
      itemFrom: [],
      itemTo: [],
      itemProductCount: [],
      itemADTCount: 1,
      itemCHDCount: 0,
      itemINFCount: 0,
      itemCur: [],
      itemCostCur: [],
      itemTotalCost: [],
      itemTotalCurCost: [],
      itemTotalAccountPrice: [],
      itemTotalPrice: [],

      itemRemarksVC1: [],
      itemRemarksVC2: [],
      itemRemarksVC3: [],
      itemRemarksVC4: [],
      itemRemarksVC5: [],
      itemRemarksVC6: [],
      itemRemarksINT2: [],
      itemRemarksDC1: [],
      itemRemarksJSON1: [],
      itemRemarksJSON2: [],
      itemRemarksJSON3: [],

      itemStartTime: [],
      itemFinishTime: [],

      paxADTGender: [],
      paxADTName: [],
      paxADTSurname: [],
      paxADTBDate: [],
      paxADTID: [],
      paxADTTicketNo: [],
      paxADTReturnTicketNo: [],

      paxCHDGender: [],
      paxCHDName: [],
      paxCHDSurname: [],
      paxCHDBDate: [],
      paxCHDID: [],
      paxCHDTicketNo: [],
      paxCHDReturnTicketNo: [],

      paxExcelStatus: false,
      paxExcelSelected: "false",

      paxPriceADT: [],
      paxPriceCHD: [],
      paxPriceINF: [],

      itemNote: [],

      paxAccountPriceADT: [],
      paxAccountPriceCHD: [],
      paxAccountPriceINF: [],

      paxCostADT: [],
      paxCostCHD: [],
      paxCostINF: [],

      paxTaxADT: [],
      paxTaxCHD: [],
      paxTaxINF: [],

      paxINFGender: [],
      paxINFName: [],
      paxINFSurname: [],
      paxINFBDate: [],
      paxINFID: [],
      paxINFTicketNo: [],
      paxINFReturnTicketNo: [],

      itemSupplierPaymentType: "",
      itemSupplierPaymentMethod: "",
      itemSupplierPaymentAmount: "",
      itemSupplierPaymentCur: "",

      itemPaymentStatus: true,
      itemPaymentType: "",
      itemPaymentMethod: "",
      itemPaymentAmount: "",
      itemPaymentCur: "",

      resCurTRY: "",
      resCurUSD: "",
      resCurEUR: "",
      resCurSTG: "",

    });

    const myCur = computed(() => {
      let tmpData = store.getters.curRateList;
      return setCurData(tmpData);
    });

    function setCurData(tmpData) {
      if (tmpData.data !== undefined) newReservations.value.resCurTRY = "1";
      if (tmpData.data !== undefined) {
        return tmpData.data[0];
      } else {
        return {
          CurTRY: 1,
          CurUSD: 0,
          CurEUR: 0,
          CurSTG: 0,
        }
      }
    }

    const myBankAccount = computed(() => {
      return store.getters.bankAccountsList;
    });
    const myBankPos = computed(() => {
      return store.getters.bankPosList;
    });

    const myCreditCard = computed(() => {
      return store.getters.companyCreditCardList;
    });

    store.dispatch(Actions.FLIGHT_EXTRA_LIST, {});
    const myFlightExtraList = computed(() => {
      return store.getters.flightExtrasList;
    });

    const myTotalFlightPrice = computed(() => {
      let myTCur = newReservations.value.resSaleCur;

      let myCostCur = newReservations.value.itemCostCur[0];
      let myCurRate = 0;
      let myCostCurRate = 0;
      if (myTCur == 'TRY') myCurRate = myCur.value.CurTRY;
      if (myTCur == 'USD') myCurRate = myCur.value.CurUSD;
      if (myTCur == 'EUR') myCurRate = myCur.value.CurEUR;
      if (myTCur == 'STG') myCurRate = myCur.value.CurSTG;

      if (myCostCur == 'TRY') myCostCurRate = myCur.value.CurTRY;
      if (myCostCur == 'USD') myCostCurRate = myCur.value.CurUSD;
      if (myCostCur == 'EUR') myCostCurRate = myCur.value.CurEUR;
      if (myCostCur == 'STG') myCostCurRate = myCur.value.CurSTG;
      let tmpCostResCur = {
        CUR: "",
        TOTALCOST: 0,
        ADT: 0,
        CHD: 0,
        INF: 0,
      };
      if (myTCur == myCostCur) {
        tmpCostResCur = {
          CUR: myTCur,
          TOTALCOST: (newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount) + (newReservations.value.paxCostCHD[0] * newReservations.value.itemCHDCount) + (newReservations.value.paxCostINF[0] * newReservations.value.itemINFCount),
          ADT: Number((newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount)),
          CHD: Number((newReservations.value.paxCostCHD[0] * newReservations.value.itemCHDCount)),
          INF: Number((newReservations.value.paxCostINF[0] * newReservations.value.itemINFCount)),
        }
      } else {
        let tmpADTPrice = ((newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount) * myCostCurRate) / myCurRate;
        let tmpCHDPrice = ((newReservations.value.paxCostCHD[0] * newReservations.value.itemCHDCount) * myCostCurRate) / myCurRate;
        let tmpINFPrice = ((newReservations.value.paxCostINF[0] * newReservations.value.itemINFCount) * myCostCurRate) / myCurRate;

        tmpCostResCur = {
          CUR: myCostCur,
          TOTALCOST: Number((tmpADTPrice + tmpCHDPrice + tmpINFPrice).toFixed(2)),
          ADT: Number((tmpADTPrice).toFixed(2)),
          CHD: Number((tmpCHDPrice).toFixed(2)),
          INF: Number((tmpINFPrice).toFixed(2)),
        }
      }

      /*******RETURN FLIGHT*******/
      let myCostCurReturn = newReservations.value.itemCostCur[1];
      let myCostCurRateReturn = 0;

      if (myCostCurReturn == 'TRY') myCostCurRateReturn = myCur.value.CurTRY;
      if (myCostCurReturn == 'USD') myCostCurRateReturn = myCur.value.CurUSD;
      if (myCostCurReturn == 'EUR') myCostCurRateReturn = myCur.value.CurEUR;
      if (myCostCurReturn == 'STG') myCostCurRateReturn = myCur.value.CurSTG;
      let tmpCostResCurReturn = {
        CUR: "",
        TOTALCOST: 0,
        ADT: 0,
        CHD: 0,
        INF: 0,
      };
      if (myTCur == myCostCurReturn) {
        tmpCostResCurReturn = {
          CUR: myTCur,
          TOTALCOST: (newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount) + (newReservations.value.paxCostCHD[1] * newReservations.value.itemCHDCount) + (newReservations.value.paxCostINF[1] * newReservations.value.itemINFCount),
          ADT: Number((newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount)),
          CHD: Number((newReservations.value.paxCostCHD[1] * newReservations.value.itemCHDCount)),
          INF: Number((newReservations.value.paxCostINF[1] * newReservations.value.itemINFCount)),
        }
      } else {
        let tmpADTPrice = ((newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount) * myCostCurRateReturn) / myCurRate;
        let tmpCHDPrice = ((newReservations.value.paxCostCHD[1] * newReservations.value.itemCHDCount) * myCostCurRateReturn) / myCurRate;
        let tmpINFPrice = ((newReservations.value.paxCostINF[1] * newReservations.value.itemINFCount) * myCostCurRateReturn) / myCurRate;

        tmpCostResCurReturn = {
          CUR: myCostCur,
          TOTALCOST: Number((tmpADTPrice + tmpCHDPrice + tmpINFPrice).toFixed(2)),
          ADT: Number((tmpADTPrice).toFixed(2)),
          CHD: Number((tmpCHDPrice).toFixed(2)),
          INF: Number((tmpINFPrice).toFixed(2)),
        }
      }


      let myTempPrices = {
        CUR: newReservations.value.resSaleCur,
        COSTCUR: newReservations.value.itemCostCur,
        ITEMS: [
          {
            /*******DEP FLIGHT*********/
            PRICE: {
              ADT: (newReservations.value.paxPriceADT[0] * newReservations.value.itemADTCount).toFixed(2),
              CHD: (newReservations.value.paxPriceCHD[0] * newReservations.value.itemCHDCount).toFixed(2),
              INF: (newReservations.value.paxPriceINF[0] * newReservations.value.itemINFCount).toFixed(2),
            },
            COST: {
              ADT: (newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount).toFixed(2),
              CHD: (newReservations.value.paxCostCHD[0] * newReservations.value.itemCHDCount).toFixed(2),
              INF: (newReservations.value.paxCostINF[0] * newReservations.value.itemINFCount).toFixed(2),
            },
            TAX: {
              ADT: (newReservations.value.paxTaxADT[0] * newReservations.value.itemADTCount).toFixed(2),
              CHD: (newReservations.value.paxTaxCHD[0] * newReservations.value.itemCHDCount).toFixed(2),
              INF: (newReservations.value.paxTaxINF[0] * newReservations.value.itemINFCount).toFixed(2),
            },
            TOTAL: {
              PRICE: ((newReservations.value.paxPriceADT[0] * newReservations.value.itemADTCount) + ((newReservations.value.paxPriceCHD[0] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxPriceINF[0] || 0) * newReservations.value.itemINFCount)).toFixed(2),
              COST: ((newReservations.value.paxCostADT[0] * newReservations.value.itemADTCount) + ((newReservations.value.paxCostCHD[0] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxCostINF[0] || 0) * newReservations.value.itemINFCount)).toFixed(2),
              TAX: ((newReservations.value.paxTaxADT[0] * newReservations.value.itemADTCount) + ((newReservations.value.paxTaxCHD[0] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxTaxINF[0] || 0) * newReservations.value.itemINFCount)).toFixed(2),
              PROFIT: Number((newReservations.value.paxPriceADT[0] * newReservations.value.itemADTCount) + ((newReservations.value.paxPriceCHD[0] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxPriceINF[0] || 0) * newReservations.value.itemINFCount)) - Number(tmpCostResCur.TOTALCOST.toFixed(2)),
            },
          },
          {
            /*******ARR FLIGHT*********/
            PRICE: {
              ADT: (newReservations.value.paxPriceADT[1] * newReservations.value.itemADTCount).toFixed(2),
              CHD: (newReservations.value.paxPriceCHD[1] * newReservations.value.itemCHDCount).toFixed(2),
              INF: (newReservations.value.paxPriceINF[1] * newReservations.value.itemINFCount).toFixed(2),
            },
            COST: {
              ADT: (newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount).toFixed(2),
              CHD: (newReservations.value.paxCostCHD[1] * newReservations.value.itemCHDCount).toFixed(2),
              INF: (newReservations.value.paxCostINF[1] * newReservations.value.itemINFCount).toFixed(2),
            },
            TAX: {
              ADT: (newReservations.value.paxTaxADT[1] * newReservations.value.itemADTCount).toFixed(2),
              CHD: (newReservations.value.paxTaxCHD[1] * newReservations.value.itemCHDCount).toFixed(2),
              INF: (newReservations.value.paxTaxINF[1] * newReservations.value.itemINFCount).toFixed(2),
            },
            TOTAL: {
              PRICE: ((newReservations.value.paxPriceADT[1] * newReservations.value.itemADTCount) + ((newReservations.value.paxPriceCHD[1] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxPriceINF[1] || 0) * newReservations.value.itemINFCount)).toFixed(2),
              COST: ((newReservations.value.paxCostADT[1] * newReservations.value.itemADTCount) + ((newReservations.value.paxCostCHD[1] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxCostINF[1] || 0) * newReservations.value.itemINFCount)).toFixed(2),
              TAX: ((newReservations.value.paxTaxADT[1] * newReservations.value.itemADTCount) + ((newReservations.value.paxTaxCHD[1] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxTaxINF[1] || 0) * newReservations.value.itemINFCount)).toFixed(2),
              PROFIT: Number((newReservations.value.paxPriceADT[1] * newReservations.value.itemADTCount) + ((newReservations.value.paxPriceCHD[1] || 0) * newReservations.value.itemCHDCount) + ((newReservations.value.paxPriceINF[1] || 0) * newReservations.value.itemINFCount)) - Number(tmpCostResCurReturn.TOTALCOST.toFixed(2)),
            },
          },
        ],
        TOTALPRICE: {
          PRICE: (((newReservations.value.paxPriceADT[0] + (newReservations.value.paxPriceADT[1] || 0)) * newReservations.value.itemADTCount) +
              (((newReservations.value.paxPriceCHD[0] || 0) + (newReservations.value.paxPriceCHD[1] || 0)) * newReservations.value.itemCHDCount) +
              (((newReservations.value.paxPriceINF[0] || 0) + (newReservations.value.paxPriceINF[1] || 0)) * newReservations.value.itemINFCount)).toFixed(2)
        },
        COST_SALE_CUR: {
          CUR: myCostCur,
          TOTALCOST: (tmpCostResCur.ADT || 0) + (tmpCostResCur.CHD || 0) + (tmpCostResCur.INF || 0) + (tmpCostResCurReturn.ADT || 0) + (tmpCostResCurReturn.CHD || 0) + (tmpCostResCurReturn.INF || 0),
          ADT: Number(tmpCostResCur.ADT).toFixed(2),
          CHD: Number(tmpCostResCur.CHD + tmpCostResCurReturn.CHD).toFixed(2),
          INF: Number(tmpCostResCur.INF + tmpCostResCurReturn.INF).toFixed(2),
        },
        COST_SALE_CUR_DEPERTURE: tmpCostResCur,
        COST_SALE_CUR_ARRIVAL: tmpCostResCurReturn,
      };
      console.log(myTempPrices);
      return myTempPrices;
    });


    const validationSchema = Yup.object().shape({
      roomTitle: Yup.string().required().label("Oda İsmi"),
      roomCategory: Yup.string().required().label("Oda Kategory"),
      roomADT: Yup.string().required().label("Yetişkin Kapasite"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      // submitButtonRef.value.disabled = true;
      // Activate indicator
      // submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      //let isActive = (roomActive.value.checked) ? 'active' : 'passive';
      //let isSale = (roomSale.value.checked) ? 'open' : 'stop';


      let payloadFlights = {};
      let payloadPax = {};

      if (newReservations.value.itemADTCount > 0) {
        payloadPax = {
          PaxType: 'ADT',
          PaxGender: newReservations.value.paxADTGender[0],
          PaxName: newReservations.value.paxADTName[0],
          PaxSurname: newReservations.value.paxADTSurname[0],
          PaxBirthdate: newReservations.value.paxADTBDate[0],
          PaxID: newReservations.value.paxADTID[0],
          PaxTicketNo: newReservations.value.paxADTTicketNo[0],
          PaxReturnTicketNo: newReservations.value.paxADTReturnTicketNo[0],
        };
      }

      if (newReservations.value.itemCHDCount > 0) {
        payloadPax = {
          PaxType: 'CHD',
          PaxGender: newReservations.value.paxCHDGender[0],
          PaxName: newReservations.value.paxCHDName[0],
          PaxSurname: newReservations.value.paxCHDSurname[0],
          PaxBirthdate: newReservations.value.paxCHDBDate[0],
          PaxID: newReservations.value.paxCHDID[0],
          PaxTicketNo: newReservations.value.paxCHDTicketNo[0],
          PaxReturnTicketNo: newReservations.value.paxCHDReturnTicketNo[0],
        };
      }

      if (newReservations.value.itemINFCount > 0) {
        payloadPax = {
          PaxType: 'INF',
          PaxGender: newReservations.value.paxINFGender[0],
          PaxName: newReservations.value.paxINFName[0],
          PaxSurname: newReservations.value.paxINFSurname[0],
          PaxBirthdate: newReservations.value.paxINFBDate[0],
          PaxID: newReservations.value.paxINFID[0],
          PaxTicketNo: newReservations.value.paxINFTicketNo[0],
          PaxReturnTicketNo: newReservations.value.paxINFReturnTicketNo[0],
        };
      }

      for (let i = 0; i < newReservations.value.itemFlightCount; i++) {
        //let myFlightFrom = document.getElementById("flightFrom3") as HTMLInputElement;
        //let myFlightTo = document.getElementById("flightTo" + i) as HTMLInputElement;
        let matchesFrom = newReservations.value.itemFrom[0].match(/\(([^)]+)\)/);
        let matchesTo = newReservations.value.itemTo[0].match(/\(([^)]+)\)/);
        //console.log(myFlightFrom.value + " "+ matchesFrom);
        //console.log(myFlightTo.value + " "+ matchesTo);
        if (matchesFrom) {
          newReservations.value.itemFrom[i] = matchesFrom[1];
        }
        if (matchesTo) {
          newReservations.value.itemTo[i] = matchesTo[1];
        }
        let paxJSON ="";
        paxJSON = JSON.stringify({
          ADT: (newReservations.value.itemADTCount > 0) ? payloadPax : {},
          CHD: (newReservations.value.itemCHDCount > 0) ? payloadPax : {},
          INF: (newReservations.value.itemINFCount > 0) ? payloadPax : {},
        });
        if(props.prcKey=='UPDATE') paxJSON = JSON.stringify(payloadPax)
        payloadFlights[i] = {
          ID: props.itemID,
          ReservationID: props.resID,
          MasterProductID: (myDetailsProduct.value.MasterProductID) ? myDetailsProduct.value.MasterProductID : props.itemID,
          ProductType: 'FLIGHT',
          ProcessType: 'EXTRA',
          ProcessDate: dateConvert.method.flatpickerToDateYM(newReservations.value.itemProcessDate[i]),
          StartDate: dateConvert.method.convertToDateYM(newReservations.value.itemStartDate[i]),
          FinishDate: dateConvert.method.convertToDateYM(newReservations.value.itemFinishDate[i]),
          SupplierID: newReservations.value.itemSupplier[i],
          From: newReservations.value.itemFrom[i],
          To: newReservations.value.itemTo[i],
          Campaign: '{}',
          ProductCount: newReservations.value.itemADTCount + newReservations.value.itemCHDCount,
          ADTCount: newReservations.value.itemADTCount,
          CHDCount: newReservations.value.itemCHDCount,
          INFCount: newReservations.value.itemINFCount,
          Cur: newReservations.value.resSaleCur,
          CostCur: newReservations.value.itemCostCur[i],
          TotalCost: myTotalFlightPrice.value.ITEMS[i].TOTAL.COST,
          TotalAccountPrice: myTotalFlightPrice.value.ITEMS[i].TOTAL.PRICE,
          TotalPrice: myTotalFlightPrice.value.ITEMS[i].TOTAL.PRICE,
          TotalCurCost: (i == 0) ? myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.TOTALCOST : myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.TOTALCOST,
          RemarksVC1: newReservations.value.itemRemarksVC1[i],
          RemarksVC2: newReservations.value.itemRemarksVC2[i],
          RemarksVC3: newReservations.value.itemRemarksVC3[i],
          RemarksVC4: newReservations.value.itemRemarksVC4[i],
          RemarksVC5: newReservations.value.itemRemarksVC5[i],
          RemarksVC6: newReservations.value.itemRemarksVC6[i],
          RemarksINT2: newReservations.value.itemRemarksINT2[i],
          Note: newReservations.value.itemNote[i],
          RemarksJSON1: paxJSON,
          RemarksJSON2: JSON.stringify({
            Price: {
              ADT: newReservations.value.paxPriceADT[i],
              CHD: (newReservations.value.itemCHDCount >= 1) ? newReservations.value.paxPriceCHD[i] : 0,
              INF: (newReservations.value.itemINFCount >= 1) ? newReservations.value.paxPriceINF[i] : 0,
              CUR: newReservations.value.resSaleCur,
            },
            AccountPrice: {
              ADT: newReservations.value.paxPriceADT[i],
              CHD: (newReservations.value.itemCHDCount >= 1) ? newReservations.value.paxPriceCHD[i] : 0,
              INF: (newReservations.value.itemINFCount >= 1) ? newReservations.value.paxPriceINF[i] : 0,
              CUR: newReservations.value.resSaleCur,
            },
            Cost: {
              ADT: newReservations.value.paxCostADT[i],
              CHD: (newReservations.value.itemCHDCount >= 1) ? newReservations.value.paxCostCHD[i] : 0,
              INF: (newReservations.value.itemINFCount >= 1) ? newReservations.value.paxCostINF[i] : 0,
              CUR: newReservations.value.itemCostCur[i],
              ADTCURCOST: (i == 0) ? (myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.ADT / newReservations.value.itemADTCount) : (myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.ADT / newReservations.value.itemADTCount),
              CHDCURCOST: (i == 0) ? (myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.CHD / newReservations.value.itemCHDCount) : (myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.CHD / newReservations.value.itemCHDCount),
              INFCURCOST: (i == 0) ? (myTotalFlightPrice.value.COST_SALE_CUR_DEPERTURE.INF / newReservations.value.itemINFCount) : (myTotalFlightPrice.value.COST_SALE_CUR_ARRIVAL.INF / newReservations.value.itemINFCount),
            },
            Tax: {
              ADT: newReservations.value.paxTaxADT[i],
              CHD: (newReservations.value.itemCHDCount >= 1) ? newReservations.value.paxTaxCHD[i] : 0,
              INF: (newReservations.value.itemINFCount >= 1) ? newReservations.value.paxTaxINF[i] : 0,
              CUR: newReservations.value.itemCostCur[i],
            },
          }),
          RemarksJSON3: JSON.stringify({
            FlightDepTime: newReservations.value.itemStartTime[i],
            FlightArrTime: newReservations.value.itemFinishTime[i],
          }),
        };
      }

      let payloadPayment = {
        Method: newReservations.value.itemPaymentType,
        MethodID: newReservations.value.itemPaymentMethod,
        Amount: newReservations.value.itemPaymentAmount,
        Cur: newReservations.value.itemPaymentCur,
      };
      let payloadSupplierPayment = {
        Method: newReservations.value.itemSupplierPaymentType,
        MethodID: newReservations.value.itemSupplierPaymentMethod,
        Amount: newReservations.value.itemSupplierPaymentAmount,
        Cur: newReservations.value.itemSupplierPaymentCur,
      };
      let payloadCur = {
        TRY: myCur.value.CurTRY,
        USD: myCur.value.CurUSD,
        EUR: myCur.value.CurEUR,
        STG: myCur.value.CurSTG,
      };

      const payload1 = {
        ID: props.itemID,
        ReservationID: props.resID,
        Department: newReservations.value.resDepartment,
        Market: newReservations.value.resMarket,
        AccountID: newReservations.value.resAccount,
        //productFlightCount: (returnFlightStatus) ? 1 : 2,
        productData: JSON.stringify(payloadFlights),
        SaleCur: newReservations.value.resSaleCur,
        Payment: JSON.stringify(payloadPayment),
        SupplierPayment: JSON.stringify(payloadSupplierPayment),
        CurRate: JSON.stringify(payloadCur),
      }

      console.log(payloadFlights[0])
      let myMethod = 'RESERVATIONS_PRODUCT_INSERT';
      let msPayload = payload1;
      if (props.prcKey == 'UPDATE') {
        myMethod = 'RESERVATIONS_PRODUCT_UPDATE';
        msPayload = payloadFlights[0];
      }
      store.dispatch(Actions[myMethod], msPayload)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // submitButtonRef.value.disabled = false;
              //submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              //hideModal(newRoomModalRef.value);
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getUserErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        //submitButtonRef.value.disabled = false;
        //submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };



    let returnFlightStatus = ref(false)
    const setTrip = () => {
      if (newReservations.value.itemTrip == 'ONEWAY') {
        returnFlightStatus.value = false;
        newReservations.value.itemFlightCount = 1;
      } else {
        returnFlightStatus.value = true;
        newReservations.value.itemFlightCount = 2;
      }
    }

    const addEventToNumber = () => {
      var priceInput = document.querySelectorAll(".priceInput");
      priceInput.forEach(function (element) {
        console.log(element);
        element.addEventListener("keyup", commonf.inputDecimal, false);
      });
      return true;
    }

    const testFunc = () => {
      addEventToNumber();
      console.log("yhkhgjk");
      return true;
    };


    return {
      myDetailsRes,
      myDetailsProduct,
      testFunc,
      addEventToNumber,
      commonf,
      myFlightExtraList,
      myCur,
      myTotalFlightPrice,
      roomPriceType,
      roomPricePlatform,
      weekDaysList,
      roomMinAccType,
      roomProfitType,
      curList,
      flightType,
      ticketTypes,
      genderTypes,
      paymentMethods,
      airlineClassesTypes,
      curExchangeType,
      roomActive,
      roomSale,
      returnFlightStatus,
      validationSchema,
      submit,
      submitButtonRef,
      newRoomModalRef,
      newReservations,
      setTrip,
      myBankAccount,
      myBankPos,
      myCreditCard,
      MasterConfig,
    };
  },
});
