
import {computed, defineComponent, onMounted, ref} from "vue";
import ItemAdd from "@/components/reservations/ReservationFlightAdd.vue";
import ItemUpdate from "@/components/reservations/ReservationFlightUpdate.vue";
import ItemEdit from "@/components/reservations/ReservationFlightChange.vue";
import ItemCancel from "@/components/reservations/ReservationFlightCancel.vue";
import ItemExtra from "@/components/reservations/ReservationFlightExtra.vue";

import Swal from "sweetalert2/dist/sweetalert2.js";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRoute} from "vue-router";
import {dateConvert} from "@/core/filters/datatime";
import {commonf} from "@/core/filters/common";

export default defineComponent({
  name: "overview",
  components: {
    ItemAdd,
    ItemUpdate,
    ItemEdit,
    ItemCancel,
    ItemExtra,
  },
  setup() {
    const searchName = ref("");
    onMounted(() => {
      setCurrentPageBreadcrumbs("Bilet Detayları", ["Rezervasyon"]);
    });
    const route = useRoute();
    const id = route.params.id;
    const payload = {
      ID: id,
    }

    /**************************/
    store.dispatch(Actions.AIRLINE_LIST, {});
    store.dispatch(Actions.AIRLINE_CLASSES_LIST_ALL, {});
    const payload4 = {
      Type: 'AIRLINE',
    }
    store.dispatch(Actions.SUPPLIERS_LIST_ALL, payload4);
    store.dispatch(Actions.BANKACCOUNTS_LIST, {});
    store.dispatch(Actions.BANKPOS_LIST, {});
    store.dispatch(Actions.COMPANY_CREDIT_CARD_LIST, {});
    const payloadCRL = {
      Type: 'AUTO',
      validDate: dateConvert.method.getTodayYMD(),
      is_active: 'active',
      limit: 1,
    }
    store.dispatch(Actions.CUR_RATE_LIST, payloadCRL);
    /**************************/

    //console.log(payload);
    store.dispatch(Actions.RESERVATIONS_DETAILS, payload);
    const myDetails = computed(() => {
      return store.getters.reservationsDetails;
    });

    const payload3 = {
      ReservationID: id,
      ProductType: 'FLIGHT'
      //is_active: "active",
    }
    store.dispatch(Actions.RESERVATION_PRODUCTS_LIST, payload3);
    const myList = computed(() => {
      return store.getters.reservationProductsList;
    });

    const filterFieldName = ['İsim', 'Soyisim', 'Pax Türü', 'Nereden', 'Nereye', 'Uçuş Numarası', 'PNR'];
    const myFilteredList = computed(() => {
      if (myList.value.data !== undefined) {
        return (myList.value.data).filter(itemFil =>
            (itemFil.PaxName !== undefined && !(itemFil.PaxName).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.PaxSurname !== undefined && !(itemFil.PaxSurname).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.PaxType !== undefined && !(itemFil.PaxType).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.From !== undefined && !(itemFil.From).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.To !== undefined && !(itemFil.To).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.RemarksVC1 !== undefined && !(itemFil.RemarksVC1).toLowerCase().indexOf(searchName.value.toLowerCase())) ||
            (itemFil.RemarksVC4 !== undefined && !(itemFil.RemarksVC4).toLowerCase().indexOf(searchName.value.toLowerCase()))
        );
      } else {
        return [];
      }
    });



    const updateActive = (ID, Active) => {
      const payload = {
        ID: ID,
        ReservationID: id,
        ProductType: 'FLIGHT',
        is_active: (Active == 'active') ? "passive" : "active",
      }
      store.dispatch(Actions.RESERVATIONS_PRODUCT_UPDATE, payload)
    }

    const deleteItem = (ID) => {
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload = {
            ID: ID,
            ProductType: 'FLIGHT',
            ReservationID: id,
          }
          store.dispatch(Actions.RESERVATIONS_PRODUCT_DELETE, payload)
              .then(() => {
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getHotelOverCommissionErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });

    }

    let itemID = ref<number>(0);
    let prcKey = ref<string>('');

    function setItems(ID, KEY) {
      itemID.value = ID;
      prcKey.value = KEY;
    }

    return {
      id,
      myDetails,
      myList,
      myFilteredList,
      searchName,
      filterFieldName,
      dateConvert,
      itemID,
      prcKey,
      setItems,
      updateActive,
      deleteItem,
      commonf,
    };
  },
});
