const paymentMethods = {
  "TYPES" : [
    {
      Value : 'CASH',
      Title : 'NAKİT',
      Shorthand : 'CASH',
    },
    {
      Value : 'CARD',
      Title : 'KREDİ KARTI',
      Shorthand: 'CARD',
    },
    {
      Value : 'BANK',
      Title : 'BANKA HAVALESİ',
      Shorthand: 'BANK',
    },
    {
      Value : 'CURRENT',
      Title : 'AVANS HESAP',
      Shorthand: 'CURRENT',
    },],
};
export default paymentMethods;
