const ticketTypes = {
  "TYPES" : [
    {
      Value : 'AGENT',
      Title : 'AGENT',
      Shorthand : 'AG',
    },
    {
      Value : 'SERVICE',
      Title : 'SERVİS/XML',
      Shorthand: 'XML',
    },],
};
export default ticketTypes;
